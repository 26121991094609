import React from 'react';

import Banner from '../../components/Banner/Banner';
import Section from '../../components/Section/Section';
import Introduction from '../../components/Introduction/Introduction';
import Contact from '../../components/Contact/Contact';

import bannerImage from '../../assets/images/promotional/page_contact.jpg';

const find = () => (
    <React.Fragment>
        <Banner
            image = { bannerImage }
            imageAlt = 'Beauty Salon Background'
            //main = 'Invest in your brows, it is the crown you never take off'
            //description = 'Come and discover your oasis. It has never been easier to take a break from stress and the harmful factors that surround you every day!'
            description = 'The fastest way to book an appointment is through our glossgenius site; You can also book appointments through phone number; Call or message to book an appointment: 240-474-2902 or 240-474-2903 or message us'
            linkLeftTo = '/services'
            linkLeftText = 'View services'
            linkRightTo='https://foreverbeautysalon.glossgenius.com'
            linkRightText='Book Now'
            booklink='https://www.facebook.com/foreverbeautysalonlexington'
            scrollTo = 'intro' />

        <Section id = 'intro'>
            <Introduction
                main = 'Find us'
                description = '' />
            <Contact
                formDescription = 'For all inquiries please get in touch with us.'
                contactDescription = ''
                phone = '(240) 474 2902,  (240) 474 2903'
                email = 'hello@forever-beautysalon.com'
                address = '400 Park Place suite 3 (Inside Privé Salon Suites), Lexington, Kentucky'
                time = 'Tue-Sun: 10:00 AM - 6:00 PM, Mon: 1:00 PM - 6:00 PM'
                coordinates = {{ lat: 51.512333, lng: -0.30616 }} />
        </Section>
    </React.Fragment>
);

export default find;
