import React from 'react';

import Preface from '../../components/Preface/Preface';
import Section from '../../components/Section/Section';
import Introduction from '../../components/Introduction/Introduction';
import Informative from '../../components/Informative/Informative';
import Catalog from '../../components/Complementary/Catalog/Catalog';

import eyesAndBrowsPrefaceImage from '../../assets/images/promotional/eyes & brows.jpg';
import eyesAndBrowsPriceListImage from '../../assets/images/promotional/eyes & brows 2.jpg';

const eyesAndBrows = () => (
    <React.Fragment>
        <Preface
            image = { eyesAndBrowsPrefaceImage }
            main = 'Eyes & Brows' />

        <Section>
            <Introduction
                main = 'Brow lamination'
                description = {['Brow Lamination is a salon treatment which involves restructuring brow hairs to keep them in their desired shape. Naturally fuller brows which can be tailored to suit any brow preference.\nBrows can be worn brushed up or down depending on your style. Brow Lamination treatments are proving extremely popular thanks to their amazing results which last up to 8 weeks.\nThis treatment takes 30- 45 minutes and will give the appearance of thicker brows that are expertly styled into your desired shape.']} />
        </Section>

        <Section background = '#F8F8F8'>
            <Introduction
                main = 'Lash lift'
                description = {['A lash lift is a bit like an eyelash perm and gives the same effect as using an eyelash curler. it lasts 6-8 week and is suitable for clients no matter what their lash length. It’s a very low maintenance lash service. It means you can also use your normal makeup, mascara and makeup remover.']} />           
        </Section>

        <Section>
            <Informative
                image = { eyesAndBrowsPriceListImage }
                main = "Eyes & Brows"
                description = ''>
                <Catalog
                    data = {[
                        { name: 'Eyebrow Tinting', time: 20, price: 20 },
                        { name: 'Eyelash Tinting', time: 20, price: 20 },
                        { name: 'Brow Lamination', time: 40, price: 60 },
                        { name: 'Lash Lift', time: 40, price: 60 },
                    ]}
                    maxVisible = { 5 }
                    expandText = 'View more'
                    collapseText = 'View less'
                    linkTo = 'https://foreverbeautysalon.glossgenius.com'
                    linkText = 'Book now' />
            </Informative>
        </Section>        
    </React.Fragment>
);

export default eyesAndBrows;
