import React from 'react';

import Line from '../UI/Line/Line';
import Button from '../UI/Button/Button';
import Animation from '../../hoc/Animation/Animation';

import classes from './Informative.module.scss';

const informative = ({ id, image, main, description, linkTo, linkText, reversed, children }) => (
    <div className = { [classes.Informative, reversed ? classes.Reverse : ''].join(' ') } id = { id }>
        <div className = { classes.Image }>
            <Animation style = {{ width: '100%', height: '100%' }}><img src = { image } alt = { main } /></Animation>
        </div>

        <div className = { classes.Info }>
            <div className = { classes.Wrapper }>
                <Animation style = {{ width: '100%', margin: '0' }}>
                    <h1 className = { classes.Main }>{ main }</h1>
                    <Line color = '#B87332' style = {{ marginLeft: 0, marginRight: 0, marginBottom: 0 }} />
                    { description && <p className = { classes.Description }>{ description }</p> }
                </Animation>

                { children && <div className = { classes.Content }>{ children }</div> }
            </div>

            { linkTo && <Animation><div className = { classes.Button }><Button linkTo = { linkTo }>{ linkText }</Button></div></Animation> }
        </div>
    </div>
);

export default informative;
