import React from 'react';

//import instagramIcon from '../../assets/icons/instagram.png';
import facebookIcon from '../../assets/icons/facebook.png';
import phoneIcon from '../../assets/icons/phone.png';
import emailIcon from '../../assets/icons/email.png';

import classes from './Footer.module.scss';

const footer = () => (
	<footer className={classes.Footer}>
		<div className={classes.Line} />

		<div className={classes.Links}>
			{/* <a
				className={classes.Link}
				href='https://instagram.com/'
				target='_blank'
				rel='noopener noreferrer'>
				<img className={classes.Icon} src={instagramIcon} alt='Instagram Logo' />
			</a> */}

			<a
				className={classes.Link}
				href='https://facebook.com/foreverbeautysalonlexington'
				target='_blank'
				rel='noopener noreferrer'>
				<img className={classes.Icon} src={facebookIcon} alt='Facebook Logo' />
			</a>

			<a className={classes.Link} href='tel:240 474 2902'>
				<img className={classes.Icon} src={phoneIcon} alt='Phone Icon' />
			</a>

			<a className={classes.Link} href='mailto:hello@forever-beautysalon.com'>
				<img className={classes.Icon} src={emailIcon} alt='Email Icon' />
			</a>
		</div>

		<p className={classes.Content}>
			Copyright Forever Beauty Salon &copy; {new Date().getFullYear()} <br className={classes.Break} /> All Rights
			Reserved
			<br />
		</p>
	</footer>
);

export default footer;
