import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

import { Route, Routes, useLocation } from 'react-router-dom';
import { GOOGLE_ANALYTICS_ID } from './config/constants';

import Layout from './hoc/Layout/Layout';
import Navigation from './components/Navigation/Navigation';
import Home from './containers/Home/Home';
import Services from './containers/Services/Services';
import EyesAndBrows from './containers/Services/EyesAndBrows';
import Facial from './containers/Services/Facial';
import Gallery from './containers/Gallery/Gallery';
import Find from './containers/Find/Find';
import Admin from './containers/Admin/Admin';
import NotFound from './containers/NotFound/NotFound';
import Footer from './components/Footer/Footer';

import ReactGA from 'react-ga';

ReactGA.initialize(GOOGLE_ANALYTICS_ID);

const App = () => {
	const location = useLocation();

	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
	}, [location]);

	return (
		<Layout>
			<Helmet>
				<title>Forever Beauty Salon - Best Beauty Salon Lexington KY</title>
				<meta name="description" content="We service the Lexington KY community with our beauty salon. Visit Forever Beauty Salon for the best beauty service in Lexington, Kentucky. It is one of the best beauty salons near me. Our salon offers a wide range of services facials, threading, and waxing. We also specialize in brow lamination and eyelash tinting. We pride ourselves on providing a relaxing and comfortable atmosphere, where you can unwind. At Forever Beauty Salon, we believe that beauty is not just skin deep, it's a state of mind and we are here to help you achieve it. Come visit us and let us pamper you. We guarantee you'll leave feeling refreshed, rejuvenated, and beautiful. "/>
			</Helmet>
			<Navigation />

			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/services' element={<Services />} />
				<Route path='/services/facial' element={<Facial />} />
				<Route path='/services/eyes-and-brows' element={<EyesAndBrows />} />
				<Route path='/gallery' element={<Gallery />} />
				<Route path='/find-us' element={<Find />} />
				<Route path='/admin' element={<Admin />} />
				<Route render={() => <NotFound />} />
			</Routes>

			<Footer />
		</Layout>
	);
};

export default App;

/*

font-family: 'Open Sans', sans-serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Josefin Slab', serif;

$alabaster: #F8F8F8;
$akaroa: #D7C2A4;
$copper: #B87332;
$tundora: #444444;

*/
