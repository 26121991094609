import React from 'react';

import Banner from '../../components/Banner/Banner';
import Section from '../../components/Section/Section';
import Introduction from '../../components/Introduction/Introduction';
import Widgets from '../../components/Widgets/Widgets';
import Gallery from '../../components/Gallery/Gallery';
import Testimonials from '../../components/Testimonials/Testimonials';
import Contact from '../../components/Contact/Contact';

import bannerImage from '../../assets/images/promotional/page_home.jpg';
import threadingIcon from '../../assets/icons/threading.png';
import browsAndLashIcon from '../../assets/icons/brows and lash.png';
import waxingIcon from '../../assets/icons/waxing.png';
import facialIcon from '../../assets/icons/facial.png'
import galleryImage01 from '../../assets/images/gallery/6.jpg';
import galleryImage02 from '../../assets/images/gallery/3.jpg';
import galleryImage03 from '../../assets/images/gallery/10.jpg';

const home = () => (
    <React.Fragment>
        <Banner
            image = { bannerImage }
            imageAlt = 'Beauty Salon Background'
            main = {`Invest in your beauty, it's the confidence you'll always wear`}
            description = 'Come and discover your oasis. It has never been easier to take a break from stress and the harmful factors that surround you every day!'
            linkLeftTo = '/services'
            linkLeftText = 'View services'
            linkRightTo = 'https://foreverbeautysalon.glossgenius.com'
            linkRightText = 'Book now'
            scrollTo = 'intro' />

        <Section id = 'intro'>
            <Introduction
                id = 'intro'
                main = 'Welcome to Forever Beauty Salon'
                description = { ['Forever Beauty Salon is dedicated to providing top-notch beauty treatments to enhance your beauty. \nWe believe that feeling confident and beautiful is a key component to living a happy and fulfilled life. That\'s why we\'ve put together a team of skilled beauty experts who are dedicated to providing personalized and professional services to help you look and feel your best.\nOur salon offers a wide range of services facials, threading, and waxing. We also specialize in brow lamination and eyelash tinting. Our team is constantly updating their skills and knowledge to stay on top of the latest beauty trends and techniques.\nWe pride ourselves on providing a relaxing and comfortable atmosphere, where you can unwind. At Forever Beauty Salon, we believe that beauty is not just skin deep, it\'s a state of mind and we are here to help you achieve it.\nCome visit us and let us pamper you. We guarantee you\'ll leave feeling refreshed, rejuvenated, and beautiful.'] } />
        </Section>

        <Section
            background = '#F8F8F8'
            description = ''
            linkTo = '/services'
            linkText = 'View services'>
            <Introduction main = 'Services' />
            <Widgets
                data = {[
                    { name: "Facial", icon: facialIcon, url: '/services#facial' },
                    { name: "Threading", icon: threadingIcon, url: '/services#threading' },
                    { name: "Eyes & Brows", icon: browsAndLashIcon, url: '/services#eyesandbrows' },
                    { name: "Waxing", icon: waxingIcon, url: '/services#waxing' },
                ]} />
        </Section>

        <Section
            linkTo = '/gallery'
            linkText = 'View more'>
            <Introduction
                main = 'Gallery'
                description = '' />
            <Gallery
                data = {[ 
                    { image: galleryImage01, alt: 'Eyes & Brows' },
                    { image: galleryImage02, alt: 'Eyes & Brows' },
                    { image: galleryImage03, alt: 'Eyes & Brows' }
                ]} />
        </Section>
        
        <Section background = '#F8F8F8'>
            <Introduction main = 'Testimonials' />
            <Testimonials
                data = {[
                    { review: "I have been getting facials here since this salon opened. It is wonderful! The whole experience is so relaxing…I have fallen Love coming here!", author: "Prativa" },
                    { review: "Fabulous service, great results. Will definitely be coming again", author: "Sarah" },
                ]}/>
        </Section>

        <Section>
            <Introduction
                main = 'Find us'
                description = '' />
            <Contact
                formDescription = 'For all inquiries please get in touch with us.'
                contactDescription = ''
                phone = '(240) 474 2902'
                email = 'hello@forever-beautysalon.com'
                address = '400 Park Place suite 3 (Inside Privé Salon Suites), Lexington, Kentucky'
                time = 'Tue-Sun: 10:00 AM - 6:00 PM, Mon: 1:00 PM - 6:00 PM'
                coordinates = {{ lat: 51.512333, lng: -0.30616 }} />
        </Section>
    </React.Fragment>
);

export default home;
