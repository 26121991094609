import React, { useState } from 'react';

import { Link, NavLink } from 'react-router-dom';

import classes from './Navigation.module.scss';

import phone from '../../assets/icons/phone.png';
import email from '../../assets/icons/email.png';

const Navigation = () => {
	const [reveal, setReveal] = useState('');

	const menuHandler = (turn) => {
		if (turn === 'on') {
			document.body.style.overflow = 'hidden';
			setReveal(classes.Reveal);
		}

		if (turn === 'off') {
			document.body.style.overflow = 'visible';
			setReveal('');
		}

		if (turn === 'switch') {
			document.body.style.overflow = reveal === classes.Reveal ? 'visible' : 'hidden';
			setReveal(reveal === classes.Reveal ? '' : classes.Reveal);
		}
	};

	let upper = (
		<div className={classes.Upper}>
			<div className={classes.Social}>
				<a
					className={classes.Facebook}
					href='https://www.facebook.com/foreverbeautysalonlexington'
					target='_blank'
					rel='noopener noreferrer'>
					{' '}
				</a>
				{/* <a
					className={classes.Instagram}
					href='https://www.instagram.com/'
					target='_blank'
					rel='noopener noreferrer'>
					{' '}
				</a> */}
			</div>

			<Link className={classes.Logo} to='/' />

			<div className={classes.Contacts}>
				<div className={classes.Contact} onClick={() => window.location.assign('tel:240 474 2902')}>
					<img className={classes.Icon} src={phone} alt='Phone Icon' />
					<p className={classes.Info}>(240) 474 2902</p>
				</div>

				<div
					className={classes.Contact}
					onClick={() => window.location.assign('mailto:hello@forever-beautysalon.com')}>
					<img className={classes.Icon} src={email} alt='Email Icon' />
					<p className={classes.Info}>hello@forever-beautysalon.com</p>
				</div>
			</div>
		</div>
	);

	let lower = (
		<nav className={[classes.Lower, reveal].join(' ')}>
			<div className={classes.Social}>
				<a
					className={classes.Facebook}
					href='https://www.facebook.com/foreverbeautysalonlexington'
					target='_blank'
					rel='noopener noreferrer'>
					{' '}
				</a>
				{/* <a
					className={classes.Instagram}
					href='https://www.instagram.com/'
					target='_blank'
					rel='noopener noreferrer'>
					{' '}
				</a> */}
				<a className={classes.Phone} href='tel:240 474 2902'>
					{' '}
				</a>
			</div>

			<Link className={classes.Logo} to='/' onClick={() => menuHandler('off')} />

			<ul className={classes.Links}>
				<li className={classes.Link}>
					<NavLink
						to='/'
						end
						className={({ isActive }) => (isActive ? classes.Active : null)}
						onClick={() => menuHandler('off')}>
						Home
						<div className={classes.Underline} />
					</NavLink>
				</li>

				<li className={classes.Link}>
					<NavLink
						to='/services'
						className={({ isActive }) => (isActive ? classes.Active : null)}
						onClick={() => menuHandler('off')}>
						Services
						<div className={classes.Underline} />
					</NavLink>
				</li>

				<li className={classes.Link}>
					<NavLink
						to='/gallery'
						className={({ isActive }) => (isActive ? classes.Active : null)}
						onClick={() => menuHandler('off')}>
						Gallery & Testimonials
						<div className={classes.Underline} />
					</NavLink>
				</li>

				<li className={classes.Link}>
					<NavLink
						to='/find-us'
						className={({ isActive }) => (isActive ? classes.Active : null)}
						onClick={() => menuHandler('off')}>
						Find us
						<div className={classes.Underline} />
					</NavLink>
				</li>

				<li className={[classes.Link, classes.Book].join(' ')}>
					<a
						href='https://foreverbeautysalon.glossgenius.com'
						onClick={() => menuHandler('off')}
						target='_blank'
						rel='noopener noreferrer'>
						Book now
					</a>
				</li>
			</ul>

			<button className={classes.Menu} onClick={() => menuHandler('switch')}></button>
		</nav>
	);

	return (
		<React.Fragment>
			{upper}

			<div className={classes.Line} />

			{lower}
		</React.Fragment>
	);
};

export default Navigation;
