import React from 'react';

import Banner from '../../components/Banner/Banner';
import Section from '../../components/Section/Section';
import Introduction from '../../components/Introduction/Introduction';
import Informative from '../../components/Informative/Informative';
import Descriptive from '../../components/Complementary/Descriptive/Descriptive';
import Catalog from '../../components/Complementary/Catalog/Catalog';

import bannerImage from '../../assets/images/promotional/page_services.jpg';
import browsAndLashImage from '../../assets/images/promotional/eyes & brows.jpg';
import eyesAndBrowsImage from '../../assets/images/promotional/eyes & brows 2.jpg';
import waxingImage from '../../assets/images/promotional/waxing.jpg';
import facialImage from '../../assets/images/promotional/facial.jpg'
import packagesImage from '../../assets/images/promotional/packages.jpg';
import threadingImage from '../../assets/images/promotional/threading.jpg';
import comboImage from '../../assets/images/promotional/combo.jpg';

const services = () => (
    <React.Fragment>
        <Banner
            image = { bannerImage }
            imageAlt = 'Beauty Salon Background'
            main = 'If it makes you feel beautiful, then do it'
            //description = 'Come and discover your oasis. It has never been easier to take a break from stress and the harmful factors that surround you every day!'
            linkRightTo = 'https://foreverbeautysalon.glossgenius.com'
            linkRightText = 'Book now'
            scrollTo = 'intro' />

        <Section id = 'intro'>
            <Introduction
                main = 'Our services'
                description = '' />
        </Section>

        {/* <Section background = '#F8F8F8'>
            <Informative
                image = { microbladingImage }
                main = 'Microblading'
                linkTo = '/services/microblading'
                linkText = 'Learn more'
                reversed>
                <Descriptive
                    data = {[
                        { name: 'What is microblading?', description: 'Microblading is a semi-permanent makeup procedure that dramatically corrects or fully construct/reconstruct lost eyebrow hairs. It looks so real, it is often referred to as 3D or hairstroke tattoo. It can help define, shape, and create fullness in the eyebrow area.' },
                        { name: 'Benefits of eyebrow microblading', description: 'It gives you long-lasting results. Think of it like a tattoo that gives you the brows of your dreams. You will only need to get your brows microbladed once every one and a half to two years. It is an easy solution for people who have lost their brows due to over plucking, an illness, or chemotherapy.' }
                    ]} />
            </Informative>
        </Section> */}


        <Section background='#F8F8F8'>
            <Informative
                image = { facialImage }
                main = 'Facial'
                linkTo = '/services/facial'
                linkText = 'Learn more'
                reversed>
                <Descriptive
                    data = {[
                        { name: 'What is facial?', description: 'Facial is a cosmetic treatment that is designed to enhance and improve the appearance of the face. It is a non-invasive procedure that is performed to cleanse, exfoliate, and nourish the skin, leaving it looking refreshed and rejuvenated.' },
                        { name: 'Benefits of facial', description: 'The treatment is designed to improve your skin texture and tone, reducing the appearance of fine lines and wrinkles.' }
                    ]} />
            </Informative>
        </Section>

        <Section>
            <Informative
                image = { browsAndLashImage }
                main = 'Eyes & Brows'                
                linkTo = '/services/eyes-and-brows'
                linkText = 'Learn more'>
                <Descriptive
                    data = {[
                        { name: 'Brow lamination', description: 'Brow Lamination is a salon treatment which involves restructuring brow hairs to keep them in their desired shape. Naturally fuller brows which can be tailored to suit any brow preference.' },
                        { description: 'Brows can be worn brushed up or down depending on your style. Brow Lamination treatments are proving extremely popular thanks to their amazing results which last up to 8 weeks.' },
                        { description: 'This treatment takes  30- 45 minutes and will give the appearance of thicker brows that are expertly styled into your desired shape.' },
                        { name: 'Lash Lift', description: 'A lash lift is a bit like an eyelash perm and gives the same effect as using an eyelash curler. it lasts 6-8 week and is suitable for clients no matter what their lash length. It’s a very low maintenance lash service. It means you can also use your normal makeup, mascara and makeup remover.' }
                    ]} />
            </Informative>
        </Section>       
        
        <Section background='#F8F8F8'>
            <Introduction
                main = 'Price list'
                description = '' />
        
            <Informative
                id = 'threading'
                image = { threadingImage}
                main = "threading"
                description = ''>
                <Catalog
                    data = {[
                        {name:'Eyebrows',price:12,time:15},
                        {name:'Upper Lip',price:6,time:5},
                        {name:'Forehead',price:6,time:5},
                        {name:'Sideburns',price:12,time:15},
                        {name:'Lower Lips',price:6,time:5},
                        {name:'Chin',price:6,time:5},
                        {name:'Cheeks',price:12,time:10},
                        {name:'Full Face',price:50,time:40}
                    ]}
                    maxVisible = { 5 }
                    expandText = 'View more'
                    collapseText = 'View less'
                    linkTo = 'https://foreverbeautysalon.glossgenius.com'
                    linkText = 'Book now' />
            </Informative>

            <Informative
                id = 'eyesandbrows'
                image = { eyesAndBrowsImage }
                main = "Eyes & Brows"
                description = ''
                reversed>
                <Catalog
                    data = {[
                        { name: 'Eyebrow Tinting', time: 20, price: 20 },
                        { name: 'Eyelash Tinting', time: 20, price: 20 },
                        { name: 'Brow Lamination', time: 40, price: 60 },
                        { name: 'Lash Lift', time: 40, price: 60 },
                    ]}
                    maxVisible = { 5 }
                    expandText = 'View more'
                    collapseText = 'View less'
                    linkTo = 'https://foreverbeautysalon.glossgenius.com'
                    linkText = 'Book now' />
            </Informative>
            
            <Informative
                id = 'waxing'
                image = { waxingImage }
                main = "Waxing"
                description = ''>
                <Catalog
                    data = {[
                        {name: 'Eyebrows', time: 15, price: 15 },
                        {name:'Upper Lip', time:5,price:8},
                        {name:'Lower Lip',time:5,price:8},
                        {name:'Chin',time:8,price:6},
                        {name:'Forehead',time:5,price:8},
                        {name:'Side Burns',time:15,price:20},
                        {name:'Full Face',time:40,price:60},
                        {name:'Under Arms',time:15,price:20},
                        {name:'Full Arms',time:35,price:50},
                        {name:'Half Arms',time:20,price:30},
                        {name:'Half Legs',time:20,price:30},
                        {name:'Full Legs',time:45,price:70}
                       
                    ]}
                    maxVisible = { 5 }
                    expandText = 'View more'
                    collapseText = 'View less'
                    linkTo = 'https://foreverbeautysalon.glossgenius.com'
                    linkText = 'Book now' />
            </Informative>
            
            <Informative
                id = 'facial'
                image = { facialImage }
                main = "Facial"
                description = ''
                reversed>
                <Catalog
                    data = {[
                        {name:'Basic Facial',time:30,price:40},
                        {name:'Customized Facials Based on Speciality needs  ( Acne Treatment Facial, Anti-aging Facial, Chemical Peels, Microdermabrasion Facial, Hydrating Facial, Hyperpigmentation Facial, Gentle Man\'s Facial )',time:60,price:80}

                            ]}
                    maxVisible = { 5 }
                    expandText = 'View more'
                    collapseText = 'View less'
                    linkTo = 'https://foreverbeautysalon.glossgenius.com'
                    linkText = 'Book now' />
            </Informative>
            
            <Informative
                image = { packagesImage }
                main = "Add ons"
                description = ''>
                <Catalog
                    data = {[
                        { name: 'Eyes Treatment', price: 8 },
                        {name:'Lips Treatment',price:8},
                        {name:'Ice Globes',price:8},
                        {name:'Blackheads Removal',price:8},
                        {name:'Neck Massage',price:8},
                        {name:'Hand Massage',price:8},
                        {name:'Hydrajelly Mask',price:8}
                    ]}
                    maxVisible = { 5 }
                    expandText = 'View more'
                    collapseText = 'View less'
                    linkTo = 'https://foreverbeautysalon.glossgenius.com'
                    linkText = 'Book now' />
            </Informative>

            <Informative
                image = { comboImage }
                main = "Combo"
                description = ''>
                <Catalog
                    data = {[
                        { name: 'Eyebrows Threading/Waxing + Tinting', price: 30,time:30 },
                        {name:'Lash Lift + Tinting',price:70, time:55},
                    ]}
                    maxVisible = { 5 }
                    expandText = 'View more'
                    collapseText = 'View less'
                    linkTo = 'https://foreverbeautysalon.glossgenius.com'
                    linkText = 'Book now' />
            </Informative>
        </Section>     
    </React.Fragment>
);

export default services;
