import React from "react";

import Preface from "../../components/Preface/Preface";
import Section from "../../components/Section/Section";
import Introduction from "../../components/Introduction/Introduction";
import Informative from "../../components/Informative/Informative";
import Catalog from "../../components/Complementary/Catalog/Catalog";

import facialImage from "../../assets/images/promotional/facial.jpg";
// import microbladingImage02 from '../../assets/images/promotional/microblading02.jpg';

const services = () => (
  <React.Fragment>
    <Preface image={facialImage} main="Facial" />

    <Section>
      <Introduction
        main="What is Facial?"
        description={[
          "Facial is a cosmetic treatment that is designed to enhance and improve the appearance of the face. It is a non-invasive procedure that is performed to cleanse, exfoliate, and nourish the skin, leaving it looking refreshed and rejuvenated.\n Facial can be customized to address specific skin concerns such as acne, aging, or pigmentation. The process involves a deep cleanse, exfoliation, and extraction of blackheads and whiteheads, followed by a massage of the face, neck, and shoulders to improve circulation and promote relaxation.",
        ]}
      />
    </Section>

    <Section>
      <Introduction
        main="Benefits Of Facial"
        description={[
          "The treatment is designed to improve your skin texture and tone, reducing the appearance of fine lines and wrinkles.\nIt also stimulates collagen production, promoting firmer, youthful-looking skin. Relax and unwind as the treatment also improves circulation and promotes relaxation. Say goodbye to clogged pores and acne as the treatment unclogs pores and reduces their appearance. \nFinally, say hello to a brighter, more even complexion as the treatment reduces pigmentation.",
        ]}
      />
    </Section>

    <Section background="#F8F8F8">
      <Informative image={facialImage} main="Facial" description="">
        <Catalog
          data={[
            { name: "Basic Facial", time: 30, price: 40 },
            {
              name: "Customized Facials Based on Speciality needs  ( Acne Treatment Facial, Anti-aging Facial, Chemical Peels, Microdermabrasion Facial, Hydrating Facial, Hyperpigmentation Facial, Gentle Man's Facial )",
              time: 60,
              price: 80,
            },
          ]}
          maxVisible={5}
          expandText="View more"
          collapseText="View less"
          linkTo="https://foreverbeautysalon.glossgenius.com"
          linkText="Book now"
        />
      </Informative>
    </Section>
  </React.Fragment>
);

export default services;
